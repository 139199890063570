export const setOpenSubMenu = (e, depth1_idx, depth2_idx, depth) => {
  // setActiveTopMenu(idx)
  e.stopPropagation();
  e.preventDefault(); 

  // 서브 메뉴 li를 보임/숨김 처리
  // const topMenuLi = document.getElementById(`top-item-${idx}`);
  let subMenuLi;
  let topMenuTriangle
  if(depth === 'sub') {
    subMenuLi = document.getElementById(`sub-item-${depth1_idx}`);

    // 삼각 화살표 위/아래
    topMenuTriangle = document.getElementsByClassName('depth1-menu');
    topMenuTriangle[depth1_idx].classList.toggle('active')
    topMenuTriangle[depth1_idx].classList.toggle('menu_active')
  } else if(depth === 'inner') {
    subMenuLi = document.getElementById(`inner-item-${depth1_idx}-${depth2_idx}`);
    topMenuTriangle = document.getElementsByClassName(`depth2-menu-${depth1_idx}-${depth2_idx}`);
    topMenuTriangle[0].classList.toggle('active')
    topMenuTriangle[0].classList.toggle('menu_active')
  }

  // 서브 메뉴 li를 보임/숨김 처리
  subMenuLi.classList.toggle('active')


}





export  const handleMenuMousEnter = (isHambugerCollapsed) => {
  const mediaQ = window.matchMedia("screen and (max-width: 990px)");
  if(isHambugerCollapsed && !mediaQ.matches) {
    const sidebar = document.getElementsByClassName("sidebar-wrap")
    sidebar[0].classList.remove("sidebar-open")
    sidebar[0].classList.remove("sidebar-collapse")
    sidebar[0].classList.add("sidebar-closed")
  }
}

export const handleMenuMousLeave = (isHambugerCollapsed) => {     
    const mediaQ = window.matchMedia("screen and (max-width: 990px)");
    if(isHambugerCollapsed && !mediaQ.matches) {
    const sidebar = document.getElementsByClassName("sidebar-wrap")
    sidebar[0].classList.remove("sidebar-closed")
    sidebar[0].classList.add("sidebar-open")
    sidebar[0].classList.add("sidebar-collapse")
  }

}
