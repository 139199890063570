import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';


import { menuData } from './menuData'
import { setOpenSubMenu } from './sidbarFuns'

// CSS파일 위치 : assets/css/sidebar.css
// icnon 참고 : https://icons8.com/line-awesome
export default function Sidebar({ handleHambugerMenu, isHambugerCollapsed }) {
  const { an8, userId, roles, profileImg } = useSelector((state) => state.user );
  const topMenuIcons = [ "las la-coins", "las la-file-invoice-dollar", "las la-money-check-alt" ,"las la-users-cog",
    "las la-globe", "las la-file-contract", "las la-folder"
  ];

  return (
    <div className='position-relative'>
    <div
      id="main-sidebar_bg"
      className={isHambugerCollapsed ? '' : 'd-none'}
      onClick={handleHambugerMenu}
     ></div>
     
     <nav
        className="main-sidebar"
        // onMouseEnter={() => handleMenuMousEnter(isHambugerCollapsed)}
        // onMouseLeave={() => handleMenuMousLeave(isHambugerCollapsed)}
     >

       <div className=''>
         <div id='log-box' className={`d-flex justify-content-center align-items-center`}>
            <Link to={'/'} className='d-flex '>
              <span className={"d-block customer-logo"}>
              { !!profileImg ?
                <img
                  id="custom-img"
                  className="d-inline-block"
                  src={`${process.env.REACT_APP_API_URL}/api/user/resource/img?an8AndUserId=${an8+"_"+userId}&type=profile&fileName=${profileImg}`}
                  alt="사용자 이미지"
                /> :
                an8 === 9999 ?
                <img
                  id="custom-img"
                  className="d-inline-block"
                  src='/assets/img/logo/metlife-logo.png'
                  alt="사용자 이미지"
                />
                :
                <img
                  id="custom-img"
                  className="d-inline-block"
                  src='/assets/img/logo/wilus-logo.png'
                  alt="사용자 이미지"
                />}
              </span>
            </Link>
            <span className="d-flex align-items-center justify-content-center sidebar-toggle-icon" onClick={handleHambugerMenu}>
                <i className={`las ${isHambugerCollapsed ? "la-angle-double-right": "la-angle-double-left"} color-white font-18 font-bold`}></i>
            </span>
         </div>
       </div>
       <div className={`menu-bar `}>
           <div className={`menu`}>
                <ul className="menu-links">
                   {!!menuData && menuData.map((menu, depth1_idx) => (
                      <div key={depth1_idx} className='top-meun-wrap'>
                        {/* Top Menu */}
                        <li
                          id={`top-item-${depth1_idx}`}
                          className={`
                          ${menu.depth2 === undefined ? 'no_submenu ' : ''}  
                          ${roles.includes(menu.role) ? '' : 'd-none'}  
                          cursor-pointer depth1-menu nav-link position-relative d-flex align-items-center `}
                          onClick={(e) => setOpenSubMenu(e, depth1_idx, null, 'sub')}
                        >
                            <span className="icon d-inline-block">
                              <i className={`${topMenuIcons[depth1_idx]} font-24 color-mid`}></i>
                            </span>
                            <span className="text nav-text">{ menu.name }</span>
                        </li>
              
                        {/* depth2 */}
                        {/* .last-top-menu : nav 메뉴 전체가 펼쳐졌을 경우, 하단에 스크롤이 짤리는 경우를 대비하여 추가 */}
                        <ul className={`sub-menu ${menuData.length-1 === depth1_idx ? "last-top-menu" : ""}`} id={`sub-item-${depth1_idx}`}>
                          {menu.depth2 && menu.depth2.map((depth2, depth2_idx) => (
                            <div   key={depth2_idx} >
                              <li 
                                className={`
                                  depth2-menu cursor-pointer sub-link
                                  ${ depth2.path === "" ? `depth2-menu-${depth1_idx}-${depth2_idx}` : 'no_submenu'} position-relative`
                                }
                              >
                                {  depth2.path === "" ? 
                                // depth3 이 있을 경우
                                <div
                                  className='d-flex align-items-center ' 
                                  onClick={(e) => setOpenSubMenu(e, depth1_idx, depth2_idx, 'inner')}
                                >
                                  <span className="nav-text">
                                    <span className='caret-icon'/>
                                    { depth2.name }
                                  </span>
                                </div> :
                                // depth3 이 없을 경우
                                  <NavLink
                                    to={an8 !== 9999 ? depth2.path : (depth2.path === "/" && an8 === 9999) ? "" : "/ah174"}
                                    className={({ isActive, isPending }) => (
                                        ((depth2.path === "/ah174" && an8 === 9999) || (depth2.path === "/" && an8 !== 9999)) && isActive 
                                          ? "now"
                                          : isPending
                                              ? "pending"
                                              : "link"
                                    )}
                                  >
                                      <span className="nav-text">
                                        <span className='caret-icon'/>
                                        {/* <FontAwesomeIcon icon={faCaretRight}  className='caret-icon'/>  */}
                                        { depth2.name }
                                      </span>
                                  </NavLink> 
                                }
                              </li>
                                  
                              {/* depth3 */}
                              { depth2.depth3 ? 
                                <div className='menu'>
                                  <ul id={`inner-item-${depth1_idx}-${depth2_idx}`} className={`sub-menu depth3-wrap`}  >
                                    {depth2.depth3.map((depth3, idx) => (
                                      <li className='depth3-item cursor-pointer' key={idx}>
                                        <NavLink
                                          to={an8 !== 9999 ? depth3.path : (depth3.path === "/" && an8 === 9999) ? "" : "/ah174"}
                                          className={({ isActive, isPending }) => (
                                            ((depth3.path === "/ah174" && an8 === 9999) || (depth3.path === "/" && an8 !== 9999)) && isActive 
                                                ? "now"
                                                : isPending
                                                    ? "pending"
                                                    : "link"
                                          )}
                                        >
                                          <div className='d-flex align-items-center'>
                                            <span className="nav-text">
                                              <span className='caret-icon mg-l10'/>
                                              { depth3.name }
                                            </span>
                                          </div>
                                        </NavLink>
                                      </li>
                                    ))}
                                  </ul>
                                </div> : 
                                null }
                            </div>
                          ))} 
                        </ul>
                      </div>
                     ))} 
                  </ul>
           </div>
            
       </div>
     </nav>
   </div>
  );
}

