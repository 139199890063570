import React, { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

import FallBack from "component/common/fallBack/FallBack";
import ProtectedRoute from "component/ProtectedRoute";


const Login = React.lazy(() => import("./page/login/Login"));
const Index = React.lazy(() => import("./page/index/Index"));

// User
const MyPage = React.lazy(() => import("./page/user/mypage/MyPage"));
const UserSearch = React.lazy(() => import("./page/user/search/UserSearch"));

// CashBill
const CBSearch = React.lazy(() => import("./page/cashBill/CBSearch/CBSearch"));
const CBLog = React.lazy(() => import("./page/cashBill/CBLog/CBLog"));
const CBTemp = React.lazy(() => import("./page/cashBill/CBTemp/CBTemp"));
const CBOneRegister = React.lazy(() => import("./page/cashBill/CBOneRegister/CBOneRegister"));
const CBBulkRegisterNormal = React.lazy(() => import("./page/cashBill/CBBulkRegisterNormal/CBBulkRegisterNormal"));
const CBBulkRegisterCancel = React.lazy(() => import("./page/cashBill/CBBulkRegisterCancel/CBBulkRegisterCancel"));
const CBCustomerCheckById = React.lazy(() => import("./page/cashBill/CBCustomerCheckById/CBCustomerCheckByIdWrap"));

// Etax
const EtaxCreateInvoiceLedger = React.lazy(() => import("./page/etax/create/invoice/EtaxCreateInvoiceLedger")); // 생성 - 정발행
const EtaxIssueBillLedger = React.lazy(() => import("./page/etax/issue/EtaxIssueBillLedger"));                  // 발행 - 정발행
const EtaxIssueInvoiceLedger = React.lazy(() => import("./page/etax/issue/EtaxIssueInvoiceLedger"));            // 발행 - 역발행
const EtaxIssueSearchLedger = React.lazy(() => import("./page/etax/search/EtaxSearchLedger"));             // 조회 - 매입/매출 세금계산서
const EtaxCertication = React.lazy(() => import("./page/etax/certi/EtaxCertication"));             // 조회 - 매입/매출 세금계산서

// VAT 
// const VatReport = React.lazy(()=>import('./page/vat/reports/report/VatReport'))
// const VatEtaxSummary = React.lazy(()=>import('./page/vat/reports/etax/EtaxSummary'))
// const VatBillSummary = React.lazy(()=>import('./page/vat/reports/bill/BillSummary'))
// const VatCardReceipt = React.lazy(()=>import('./page/vat/reports/cardReceipt/CreditCardReceiptSummary'))
// const VatCardAggregation = React.lazy(()=>import('./page/vat/reports/cardAggregation/CreditCardInvoiceAggregation'))
// const VatFileCreate = React.lazy(()=>import('./page/vat/reports/file/VatFileCreate'))

// const VatBusinessCreate = React.lazy(()=>import('./page/vat/setting/business/VatBusinessCreate'))
// const VatRealtyCreate = React.lazy(()=>import('./page/vat/setting/realty/VatRealtyCreate'))
// const VatTaxPeriodCreate = React.lazy(()=>import('./page/vat/setting/taxPeriod/VatTaxPeriodCreate'))
// const VatNoExemptCreate = React.lazy(()=>import('./page/vat/setting/noExempt/VatNoExemptCreate'))
// const VatMicroRateCreate = React.lazy(()=>import('./page/vat/setting/microRate/VatMicroRateCreate'))
// const VatTaxType = React.lazy(()=>import('./page/vat/setting/taxType/VatTaxType'))

// EY

const Ah174 = React.lazy(()=>import('./page/ey/ah174/Ah174'))


const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/user/receipt/search/:corpName/:corpNum",
    element: <CBCustomerCheckById />,
  },
  {
    path: "/",
    element: <ProtectedRoute />,
    children: [{
      // errorElement: <ErrorPage/>,
      children: [
        {
          index: true,
          element: <Index />,
        },
        // 사용자 관리
        {
          path: "mypage",
          element: <MyPage />,
        },
        {
          path: "user/search",
          element: <UserSearch />,
        },
        // 현금영수증
        {
          path: "cashbill/temp",
          element: <CBTemp />,
        },
        {
          path: "cashbill/write/normal",
          element: <CBBulkRegisterNormal />,
        },
        {
          path: "cashbill/write/cancel",
          element: <CBBulkRegisterCancel />,
        },
        {
          path: "cashbill/write/one",
          element: <CBOneRegister />,
        },
        {
          path: "cashbill/search",
          element: <CBSearch />,
        },
        {
          path: "cashbill/log",
          element: <CBLog />,
        },
        // 전자세금계산서
        {
          path: "etax/create/ledger", // 생성 - 정발행
          element: <EtaxCreateInvoiceLedger />,
        },
        {
          path: "etax/issue/invoice/ledger", // 발행 - 정발행 
          element: <EtaxIssueInvoiceLedger />,
        },
        {
          path: "etax/issue/bill/ledger", // 발행 - 역발행 
          element: <EtaxIssueBillLedger />,
        },
        {
          path: "etax/search/ledger", // 조회 - 정발행
          element: <EtaxIssueSearchLedger />,
        },
        {
          path: "etax/certification", // 조회 - 정발행
          element: <EtaxCertication />,
        },
        // VAT
        // {
        //   path: "vat/report",
        //   element: <VatReport />,
        // },
        // {
        //   path: "vat/report/etax/summary",
        //   element: <VatEtaxSummary />,
        // },
        // {
        //   path: "vat/report/bill/summary", 
        //   element: <VatBillSummary />,
        // },
        // {
        //   path: "vat/report/card/receipt",
        //   element: <VatCardReceipt />,
        // },
        // {
        //   path: "vat/report/card/aggregation",
        //   element: <VatCardAggregation />,
        // },
        // {
        //   path: "vat/report/file",
        //   element: <VatFileCreate />,
        // },
        // {
        //   path: "vat/setting/business",
        //   element: <VatBusinessCreate />,
        // },
        // {
        //   path: "vat/setting/realty",
        //   element: <VatRealtyCreate />,
        // },
        // {
        //   path: "vat/setting/taxPeriod",
        //   element: <VatTaxPeriodCreate />,
        // },    
        // {
        //   path: "vat/setting/noExempt",
        //   element: <VatNoExemptCreate />,
        // },       
        // {
        //   path: "vat/setting/microRate",
        //   element: <VatMicroRateCreate />,
        // },       
        // {
        //   path: "vat/setting/taxType",
        //   element: <VatTaxType />,
        // }, 
        // EY
        {
          path: "ah174",
          element: <Ah174 />,
        }, 
      ]
    }]
  },
]);

export default function App() {
  return (
    <Suspense fallback={FallBack()}>
      <RouterProvider router={router} />
    </Suspense>
  );
}
