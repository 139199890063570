import http from "util/customAxios";

import { loginThunk } from "./userThunk";


export const login = async ( dispatch, navigate, loginData) => {
  try {
    await dispatch(loginThunk({ loginData })).unwrap();
    console.log('loginData-->', loginData);

    if(loginData.userId === "eycon1"){
      navigate("/ah174")
    }else{
      navigate("/")
    }
  
  } catch (e) {
    navigate("/")
  }
}



export const updateUserPwd = async (dispatch, passwords, setPasswords) => {
  try{
    let res = await http.put("/api/user/modify/password", {
      currentPwd: passwords.currentPwd,
      newPwd: passwords.newPwd,
    } );

    if(res.status === 200) {
      alert("비밀번호가 성공적으로 변경되었습니다.")
      setPasswords({
        currentPwd: "",
        newPwd: "",
        newPwdRe: "",
      });
    }
    return 

  } catch(err) {
    console.log("updateUser err : " , err)
    alert(`[${err.response.data.type}] : `+ err.response.data.message)
  }
}





export const setJWTLocalStorage = (jwt) => {
  window.localStorage.removeItem("JSESSIONID")
  window.localStorage.setItem("JSESSIONID", `Bearer ${jwt}`);
  http.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
};

export const setJWTHeader = () => {
  const JSESSIONID = window.localStorage.getItem("JSESSIONID");
  http.defaults.headers.common["Authorization"] = JSESSIONID;
};

export const removeJWT = () => {
  window.localStorage.removeItem("JSESSIONID");
};

