export const menuData = [
  {
    name: "현금영수증",
    path: "/",
    role: "CB",
    depth2: [
      {
        name: "생성",
        path: "",
        depth3: [
          {
            name: "개별발급",
            path: "/cashbill/write/one",
          },
          {
            name: "일괄 승인작성",
            path: "/cashbill/write/normal",
          },
          {
            name: "일괄 취소작성",
            path: "/cashbill/write/cancel",
          },
        ]
      },
      {
        name: "발급",
        path: "",
        depth3: [
          {
            name: "일괄발급",
            path: "/cashbill/temp",
          },
        ]
      },
      {
        name: "조회",
        path: "",
        depth3: [
          {
            name: "발급결과조회",
            path: "/cashbill/search",
          },
          {
            name: "발급결과로그",
            path: "/cashbill/log",
          },
        ]
      },
    ],
  },
  {
    name: "전자세금계산서",
    path: "/",
    role: "ETAX",
    depth2: [
      {
        name: "집계",
        path: "",
        depth3: [
          {
            name: "정/역발행 집계",
            path: "/etax/create/ledger",
          },
          {
            name: "정/역발행 수정작성",
            path: "/",
          },
        ]
      },
      {
        name: "발행",
        path: "",
        depth3: [
          {
            name: "매출발행",
            path: "/etax/issue/invoice/ledger",
          },
          {
            name: "매입발행",
            path: "/etax/issue/bill/ledger",
          },
        ]
      },
      {
        name: "조회",
        path: "",
        depth3: [
          {
            name: "매출/매입세금계산서 조회",
            path: "/etax/search/ledger",
          },
        ]
      },
      {
        name: "인증서",
        path: "",
        depth3: [
          {
            name: "인증서 등록",
            path: "/etax/certification",
          },
        ]
      },
    ],
  },
  {
    name: "사용자관리",
    path: "/",
    role: "ADMIN",
    depth2: [
      {
        name: "사용자조회",
        path: "/user/search",
      },
    ],
  },
  {
    name: "운영관리",
    path: "/",
    role: "EY",
    depth2: [
      {
        name: "기준정보",
        path: "/",
      },
      {
        name: "재무제표 및 주석 현황판",
        path: "/",
      },
      {
        name: "재무제표 및 주석",
        path: "/",
      },
      {
        name: "업무보고서 현황판",
        path: "/",
      },
      {
        name: "업무보고서",
        path: "/",
      },
    ],
  },
  {
    name: "업무보고서",
    path: "/",
    role: "EY",
    depth2: [
      {
        name: "AH160 회사 일반사항",
        path: "/",
      },
      {
        name: "AH001 인원 및 점포현황",
        path: "/",
      },
      {
        name: "AH073 대주주 지분현황",
        path: "/",
      },
      {
        name: "AH801 재무상태표(총괄)",
        path: "/",
      },
      {
        name: "AH802 포괄손익계산서(총괄)",
        path: "/",
      },
      {
        name: "AH679 재무상태표(일반계정)",
        path: "/",
      },
      {
        name: "AH601 재무상태표",
        path: "/",
      },
      {
        name: "AH802 포괄손익계산서(총괄)",
        path: "/",
      },
      {
        name: "AH679 재무상태표(일반계정)",
        path: "/",
      },
      {
        name: "AH601 재무상태표(원리금보장형특별계정)",
        path: "/",
      },
      {
        name: "AH174파생금융상품현황(생보) - [총괄분]",
        path: "/ah174",
      }, {
        name: "...",
        path: "/",
      },
    ],
  },
  {
    name: "보고서",
    path: "/",
    role: "EY",
    depth2: [
      {
        name: "주석 오류검증 리포트",
        path: "",
        depth3: [
          {
            name: "Part 1. 보고항목 내 검증",
            path: "/",
          },
          {
            name: "Part 2. 보고항목 간 검증",
            path: "/",
          },
          {
            name: "Part 3. 재무재표 검증",
            path: "/",
          },
        ]
      },
      {
        name: "증감분석 리포트",
        path: "/",
      },
      {
        name: "...",
        path: "/",
      }
    ],
  },
];


export const breadCrumb = {

    "일괄발급" :"/cashbill/register/one",
    "일괄승인작성" : "/cashbill/write/normal",
    "일괄취소작성" : "/cashbill/write/cancel",
    "개별발급" : "/cashbill/write/one",
    "발급결과조회" : "/cashbill/search",
    "마이페이지" : "/mypage",
    // 사용자 관리
    "사용자조회" : "/user/search",
}